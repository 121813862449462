// src/components/TheySaid.jsx
import React from 'react';
import weeblyLogo from '../assets/icons/weebly.svg'; // Replace with the actual path to your logo
import curvedLine from '../assets/icons/curvedline.svg'; // Replace with the actual path to the curved line
import userflow from '../assets/icons/flow.svg'; // Import the userflow image

const TheySaid = () => {
  return (
    <section className="relative py-20 px-4 text-center">
      <div className="max-w-3xl mx-auto mb-8 relative z-10">
        <p className="text-sm text-[#4a4a4a] uppercase mb-6">They said it</p>
        <h2 className="text-5xl font-bold text-[#1e1e1e] mb-12">
          “Designpark is the only place you need for all your design solutions!”
        </h2>
        <img src={weeblyLogo} alt="Weebly Logo" className="mx-auto h-36 mb-8" />

        {/* Curved line below the logo */}
        <img src={curvedLine} alt="Curved Line" className="mx-auto h-48 mb-12" />
      </div>

      {/* Userflow image as a decorative absolute element */}
      <img
        src={userflow}
        alt="Userflow"
        className="hidden absolute top-[300px] left-[100px] w-100 opacity-80 z-0" // Adjust positioning with top, left, etc.
        style={{ transform: 'rotate(-10deg)' }} // Optional: add transformation for rotation
      />
    </section>
  );
};

export default TheySaid;

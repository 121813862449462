import { useParams } from 'react-router-dom';

const BlogDetailsPage = () => {
  const { id } = useParams();
  return (
    <div className="container mx-auto py-10">
      <h2 className="text-3xl font-bold mb-4">Blog Post {id}</h2>
      <p>This is the details page for blog post {id}.</p>
    </div>
  );
};

export default BlogDetailsPage;

// src/pages/LandingPage.jsx
import React, { useRef } from 'react';
import Hero from '../components/Hero'; // Ensure this is the correct path and import method
import RecentWork from '../components/RecentWork';
import SimpleSteps from '../components/SimpleSteps';
import TheySaid from '../components/TheySaid';
import DesignPhilosophy from '../components/DesignPhilosophy';
import MembershipBenefits from '../components/MembershipBenefits';
import TypeOfWork from '../components/TypeOfWork';
import Membership from '../components/Membership';
import Faq from '../components/Faq';
import ScrollingTags from '../components/ScrollingTags';
import BottomCTA from '../components/BottomCTA';
import Footer from '../components/Footer';
import figmaLeft from '../assets/icons/figmaleft.svg';
import figmaRight from '../assets/icons/figmaright.svg';
import logo from '../assets/icons/logo.svg'; // Ensure the path matches your directory structure

const LandingPage = () => {
  // Create a reference for the Membership component
  const membershipRef = useRef(null);

  // Function to scroll to the Membership component
  const scrollToMembership = () => {
    membershipRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    
    <div className="bg-grid min-h-screen flex flex-col items-center">
      <img src={figmaLeft} alt="Decorative Icon 1" className="absolute top-20 left-[-35px] w-20 md:w-50 md:h-50 opacity-100" />
      <img src={figmaRight} alt="Decorative Icon 2" className="absolute bottom-10 right-[-20px] w-20 md:w-50 md:h-50 opacity-100" />

      {/* Logo at the Top */}
      <header className="py-6 flex justify-center">
        <img src={logo} alt="DesignPark Logo" className="h-8 md:h-8" />
      </header>

      <div className='w-full'>
        
        {/* Hero Section */}
        <Hero onSeePlansClick={scrollToMembership} />

        {/* Recent Work Section */}
        <RecentWork />

        {/* Simple Steps Section */}
        <SimpleSteps onSeePlansClick={scrollToMembership} /> 

        {/* They Said Section */}
        <TheySaid />

        {/* Design Philosophy Section */}
        <DesignPhilosophy />

        {/* Membership Benefits Section */}
        <MembershipBenefits />

        {/* Type Of Work Section */}
        <TypeOfWork />

        {/* Membership Section with ref */}
        <div ref={membershipRef}>
          <Membership />
        </div>

        {/* FAQ Section */}
        <Faq />

        {/* Scrolling Tags Section */}
        <ScrollingTags />

        {/* Bottom CTA Section */}
        <BottomCTA />

        {/* Footer Section */}
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;

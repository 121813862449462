import React from 'react';

const BottomCTA = () => {
  return (
    <section className="py-20 px-6 text-center">
      <div className="max-w-4sxl mx-auto">
        {/* Main Heading */}
        <h2 className="text-7xl font-bold text-[#1e1e1e] mb-6">
          Discover if Designpark is <br /> the perfect fit for you.
        </h2>

        {/* Subheading */}
        <p className="text-3xl font-bold text-[#1e1e1e] mb-8">
          (Spoiler: it definitely is!)
        </p>

        {/* Description */}
        <p className="text-lg text-[#4a4a4a] mb-12 mx-auto max-w-xl">
          Get a guided tour through Designpark, and find out how you and your team can change the way you source design, <strong>forever.</strong>
        </p>

        {/* Call-to-Action Button */}
        <a
          href="https://calendly.com/hello-designpark/intro-call-design-park"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#1e1e1e] text-white px-24 py-6 rounded-full text-xl font-semibold hover:bg-[#333] transition"
        >
          Book a call
        </a>
      </div>
    </section>
  );
};

export default BottomCTA;

// src/components/SimpleSteps.jsx
import React from 'react';
import subscribeIcon from '../assets/icons/subscribearrows.svg'; // Replace with your actual path to icons
import twodaysIcon from '../assets/icons/twodays.svg';
import happyIcon from '../assets/icons/happy.svg';
import radarIcon from '../assets/icons/radar.svg';
import tasks from '../assets/icons/tasks.svg';

const steps = [
  {
    id: 1,
    icon: subscribeIcon,
    title: 'Subscribe to a plan & request as many designs as you’d like.',
  },
  {
    id: 2,
    icon: twodaysIcon,
    title: 'Receive your design within two business days on average.',
  },
  {
    id: 3,
    icon: happyIcon,
    title: 'We’ll revise the designs until you’re 100% satisfied.',
  },
];

const SimpleSteps = ({ onSeePlansClick }) => {
  return (
    <section className="relative py-48 px-4 text-center w-full ">
      <img src={tasks} alt="Decorative Circle Graph" className="absolute top-[100px] md:top-[150px] rotate-270 right-[120px] w-24 md:w-48 md:h-48 opacity-100 z-0" />

      <div className="max-w-5xl mx-auto mb-16">
        <h2 className="text-6xl md:text-7xl font-bold text-[#1e1e1e] mb-2">
          We didn't reinvent the wheel.
        </h2>
        <h2 className="text-6xl md:text-7xl font-bold text-[#1e1e1e] mb-8">
          Just made design effortless.
        </h2>
        <p className="text-3xl text-[#4a4a4a] mb-4">Say goodbye to traditional design.</p>
        <p className="text-3xl text-[#4a4a4a]">Say hello to design your way.</p>
      </div>

      {/* Adjusted flexbox for centering */}
      <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-12 px-6">
        {steps.map((step) => (
          <div key={step.id} className="flex flex-col items-center max-w-[300px]">
            <img src={step.icon} alt={`Step ${step.id}`} className="w-20 h-20 mb-6" />
            <p className="text-lg font-medium text-[#1e1e1e]">{step.title}</p>
          </div>
        ))}
      </div>

      <button 
        className="mt-12 bg-[#1a1a1a] text-white px-12 py-4 rounded-full text-2xl font-semibold hover:bg-[#333] transition"
        onClick={onSeePlansClick}
      >
        SEE PLANS
      </button>

      <div className="flex justify-center items-center mt-4">
        <img src={radarIcon} alt="Radar" className="h-6 w-6 pulse" />
        <span className="text-[#1e1e1e] text-base ml-2">Available now</span>
      </div>
    </section>
  );
};

export default SimpleSteps;

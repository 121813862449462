import React from 'react';
import Header from '../components/Header';

const PrivacyPolicy = () => {
    return (
        <>
          <Header /> {/* Add the Header component here */}
    <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-8">Privacy Policy</h1>

      <p className="text-sm text-gray-600 mb-4">Effective Date: <strong>9/12/24</strong></p>

      <div className="space-y-8">
        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Introduction</h2>
          <p className="text-base text-gray-600">
            DesignPark ("we", "our", "us") values your privacy. This Privacy Policy explains how we collect, use, disclose, 
            and safeguard your information when you visit our website or use our design subscription services.
          </p>
          <p className="text-base text-gray-600">
            By using our services, you agree to the collection and use of information in accordance with this policy.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Information We Collect</h2>
          <p className="text-base text-gray-600">
            We collect information that you voluntarily provide to us when registering, making payments, or submitting design requests. This may include:
          </p>
          <ul className="list-disc ml-6 mt-2 text-base text-gray-600 space-y-2">
            <li><strong>Personal Information:</strong> Such as your name, email address, phone number, billing information.</li>
            <li><strong>Design Requests:</strong> The details of your design requests and project specifications.</li>
            <li><strong>Usage Data:</strong> Information about how you access and interact with our website, including IP address, browser type, and pages visited.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. How We Use Your Information</h2>
          <p className="text-base text-gray-600">
            We use the information we collect in the following ways:
          </p>
          <ul className="list-disc ml-6 mt-2 text-base text-gray-600 space-y-2">
            <li>To provide, operate, and maintain our design services.</li>
            <li>To process your payments and manage your subscription.</li>
            <li>To communicate with you regarding your design requests or account.</li>
            <li>To improve our website and services by analyzing user behavior.</li>
            <li>To send you promotional materials, newsletters, or other information (you can opt-out anytime).</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Sharing Your Information</h2>
          <p className="text-base text-gray-600">
            DesignPark does not sell or rent your personal information to third parties. However, we may share your information with:
          </p>
          <ul className="list-disc ml-6 mt-2 text-base text-gray-600 space-y-2">
            <li><strong>Service Providers:</strong> Third-party vendors we use to help operate our services (e.g., payment processors).</li>
            <li><strong>Legal Requirements:</strong> If required to do so by law, or to protect the safety, rights, or property of DesignPark.</li>
            <li><strong>Business Transfers:</strong> If we merge with or are acquired by another company, your information may be transferred as part of that transaction.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Data Security</h2>
          <p className="text-base text-gray-600">
            We take appropriate security measures to protect your personal information. However, no method of transmission over the internet or method of electronic storage is completely secure. While we strive to protect your personal information, we cannot guarantee its absolute security.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Your Privacy Rights</h2>
          <p className="text-base text-gray-600">
            Depending on your location, you may have the following rights regarding your personal information:
          </p>
          <ul className="list-disc ml-6 mt-2 text-base text-gray-600 space-y-2">
            <li><strong>Access:</strong> You have the right to request access to the personal information we hold about you.</li>
            <li><strong>Correction:</strong> You have the right to request that we correct any inaccurate or incomplete information.</li>
            <li><strong>Deletion:</strong> You have the right to request the deletion of your personal information.</li>
            <li><strong>Opt-Out:</strong> You may opt out of marketing communications by following the unsubscribe instructions in the emails we send.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Cookies</h2>
          <p className="text-base text-gray-600">
            We use cookies and similar tracking technologies to track the activity on our website and hold certain information. Cookies help improve your experience and allow us to provide tailored services. You can choose to disable cookies through your browser settings, but this may affect the functionality of the website.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. Third-Party Links</h2>
          <p className="text-base text-gray-600">
            Our website may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that website. We are not responsible for the content or privacy practices of those sites.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">9. Changes to This Privacy Policy</h2>
          <p className="text-base text-gray-600">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of the site or services following the posting of changes constitutes your acceptance of such changes.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">10. Contact Us</h2>
          <p className="text-base text-gray-600">
            If you have any questions about this Privacy Policy, please contact us:
          </p>
          <p className="text-base text-gray-600">
            <strong>Email:</strong> hello@designpark.io
          </p>
        </section>
      </div>
    </div>
    </>
  );
};

export default PrivacyPolicy;

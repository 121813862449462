// src/components/MembershipBenefits.jsx
import React from 'react';
import designBoardIcon from '../assets/icons/board.svg'; // Replace with actual path
import lightningFastIcon from '../assets/icons/lightening.svg';
import fixedRateIcon from '../assets/icons/fixed.svg';
import topQualityIcon from '../assets/icons/topnotch.svg';
import flexibleIcon from '../assets/icons/flexible.svg';
import uniqueIcon from '../assets/icons/unique.svg';

const benefits = [
  {
    id: 1,
    icon: designBoardIcon,
    title: 'Design board',
    description: 'Easily manage your design queue with a Trello board.',
    bgColor: 'bg-blue-500',
  },
  {
    id: 2,
    icon: lightningFastIcon,
    title: 'Lightning fast delivery',
    description: 'Get your design one at a time in just a few days on average.',
    bgColor: 'bg-yellow-400',
  },
  {
    id: 3,
    icon: fixedRateIcon,
    title: 'Fixed monthly rate',
    description: 'No surprises here! Pay the same fixed price each month.',
    bgColor: 'bg-sky-400',
  },
  {
    id: 4,
    icon: topQualityIcon,
    title: 'Top-notch quality',
    description: 'Insane design quality at your fingertips whenever you need it.',
    bgColor: 'bg-green-500',
  },
  {
    id: 5,
    icon: flexibleIcon,
    title: 'Flexible and scalable',
    description: 'Scale up or down as needed, and pause or cancel at any time.',
    bgColor: 'bg-purple-500',
  },
  {
    id: 6,
    icon: uniqueIcon,
    title: 'Unique and all yours',
    description: 'Each of your designs is made especially for you and is 100% yours.',
    bgColor: 'bg-red-500',
  },
];

const MembershipBenefits = () => {
  return (
    <section className=" py-48 px-8 text-center">
      <div className="max-w-7xl mx-auto mb-12">
        <h2 className="text-6xl md:text-7xl font-bold text-[#1e1e1e] mb-8">Membership Benefits</h2>
        <p className="text-2xl text-[#4a4a4a] mb-12">
          Perks so good you’ll never need to go anywhere else for your design. Seriously.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-24 gap-y-24 max-w-7xl mx-auto">
        {benefits.map((benefit) => (
          <div key={benefit.id} className="flex flex-col items-center mt-12 text-center">
            <div className={`w-24 h-24 flex items-center justify-center mb-4 rounded-xl ${benefit.bgColor}`}>
              <img src={benefit.icon} alt={benefit.title} className="w-10 h-10" />
            </div>
            <h3 className="mt-4 text-2xl font-bold text-[#1e1e1e]">{benefit.title}</h3>
            <p className="mt-2 text-lg text-[#4a4a4a]">{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MembershipBenefits;

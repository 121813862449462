// src/components/ScrollingTags.jsx
import React from 'react';

// Define tags with their background colors
const tags = [
  { id: 1, label: 'Landing Pages', bgColor: '#18B600' },
  { id: 2, label: 'Mobile Apps', bgColor: '#4E4EFF' },
  { id: 3, label: 'Logos', bgColor: '#02B8E8' },
  { id: 4, label: 'Branding', bgColor: '#FFDC00' },
  { id: 5, label: 'Slide Decks', bgColor: '#FF3E1A' },
  { id: 11, label: 'Dashboards', bgColor: '#18B600' },
  { id: 6, label: 'SaaS', bgColor: '#821BFF' },
  { id: 7, label: 'Social media', bgColor: '#FF3E1A' },
  { id: 8, label: 'Icons', bgColor: '#FFDC00' },
  { id: 9, label: 'Brand Guides', bgColor: '#4E4EFF' },
  { id: 10, label: 'Blog Graphics', bgColor: '#02B8E8' },
  { id: 12, label: '+ more', bgColor: '#1e1e1e' },
];

const ScrollingTags = () => {
  return (
    <div className="relative py-4 overflow-hidden max-w-6xl rounded-full mx-auto">
      <div className="flex space-x-4 animate-scroll">
        {/* Duplicate tags list to ensure seamless scrolling */}
        {tags.concat(tags).map((tag, index) => (
          <span
            key={index}
            className="px-8 py-2 text-white rounded-full whitespace-nowrap"
            style={{ backgroundColor: tag.bgColor }}
          >
            {tag.label}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ScrollingTags;

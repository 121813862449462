// src/components/DesignPhilosophy.jsx
import React from 'react';
import steveJobsImage from '../assets/images/stevejobs.png'; // Replace with the actual path to Steve Jobs' image

const features = [
  {
    id: 1,
    title: 'Totally async',
    description: "Don't like meetings? We don't either; so much so that we've outlawed them completely.",
  },
  {
    id: 2,
    title: 'Manage with Trello',
    description: 'Manage your design board using Trello. View active, queued, and completed tasks with ease.',
  },
  {
    id: 3,
    title: 'Invite your team',
    description: 'Invite your entire team, so anyone can submit requests and track their progress.',
  },
];

const DesignPhilosophy = () => {
  return (
    <section className=" py-0 px-6 flex flex-col lg:flex-row justify-center items-start gap-16">
      {/* Quote Section */}
      <div className="bg-white p-24 rounded-lg shadow-md max-w-2xl relative overflow-hidden">
        <blockquote className="text-3xl font-semibold text-[#1e1e1e] mb-6 z-10">
          "Design is the fundamental soul of a man-made creation that ends up expressing itself in successive outer layers of the project, or service."
        </blockquote>
        <span className="text-sm text-[#4a4a4a] mr-2 z-10 relative">Steve Jobs</span>
        
        {/* Blue Rhombus Shape */}
        <div className="absolute bottom-[-60px] right-[-10px] w-40 h-64 bg-blue-500 transform rotate-45 translate-x-1/4 translate-y-1/4 z-0"></div>
        
        {/* Steve Jobs Image */}
        <img src={steveJobsImage} alt="Steve Jobs" className="absolute bottom-[-16px] right-[-16px] w-36 h-36 z-10 m-4" />
      </div>

      {/* Features Section */}
      <div className="flex flex-col justify-center space-y-8 max-w-lg">
        {features.map((feature) => (
          <div key={feature.id} className="text-left">
            <h3 className="text-3xl font-bold text-[#1e1e1e] mb-2">{feature.title}</h3>
            <p className="text-[#4a4a4a] text-lg">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DesignPhilosophy;

// src/components/Faq.jsx
import React, { useState } from 'react';
import faqImage from '../assets/images/faqImage.png'; // Replace with the actual path to the image
import faqArrow from '../assets/icons/faqarrow.svg'; // Replace with the actual path to the arrow icon
import halfflow from '../assets/icons/halfflow.svg'; // Import the flow SVG

const faqData = [
  { question: "Why wouldn't I just hire a full-time designer?", answer: "Hiring a full-time designer is costly, and Design Park offers flexible options without long-term commitment." },
  { question: 'Is there a limit to how many requests I can have?', answer: 'No, you can have unlimited requests. They will be handled one at a time in the order they are received.' },
  { question: 'How fast will I receive my designs?', answer: 'Typically, within 48 hours depending on the complexity of the request.' },
  { question: 'What will I receive in 48 hours?', answer: 'You will receive the initial drafts or completed designs, depending on the project scope.' },
  { question: 'Who are the designers?', answer: 'Our designers are experienced professionals with a passion for delivering top-notch designs.' },
  { question: 'How does the pause feature work?', answer: 'You can pause your subscription at any time and resume when you’re ready.' },
  { question: 'How do you handle larger requests?', answer: 'Larger requests are broken down into manageable parts and delivered step by step.' },
  { question: 'What programs do you design in?', answer: 'We use industry-standard software including Figma, Adobe Creative Suite.' },
  { question: 'How do I request designs?', answer: 'Simply submit your request through our dashboard, and our team will start working on it immediately.' },
  { question: 'What if I don’t like the design?', answer: 'We offer unlimited revisions until you are 100% satisfied.' },
  { question: 'Is there any design work you don’t cover?', answer: 'We handle most design work but do not offer coding or development services.' },
  { question: 'What if I only have a single request?', answer: 'You can still use our service for one-off projects without a long-term commitment.' },
  { question: 'Are there any refunds if I don’t like the service?', answer: 'Due to the high quality and customized nature of the work, we do not offer refunds.' },
];

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle the visibility of the answer
  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="relative py-20 px-6 flex flex-col lg:flex-row justify-center items-start gap-12">
      {/* Decorative Flow Icon */}
      <img
        src={halfflow}
        alt="Flow Icon"
        className="absolute top-[000px] right-[0px] w-100 opacity-50 z-[0]" // Position behind content
        style={{ transform: 'rotate(-10deg)' }} // Optional: add transformation for rotation
      />

      {/* FAQ Section */}
      <div className="flex-1 max-w-2xl z-10">
        <h2 className="text-6xl font-bold text-[#1e1e1e] mb-8">Commonly asked Questions</h2>
        <ul className="space-y-4">
          {faqData.map((item, index) => (
            <li key={index}>
              <button
                className="w-full text-left flex justify-between items-center bg-white p-4 shadow-md rounded-lg focus:outline-none"
                onClick={() => toggleAnswer(index)}
              >
                <span className="text-base font-medium text-[#1e1e1e]">{item.question}</span>
                <img
                  src={faqArrow}
                  alt="Toggle Arrow"
                  className={`w-4 h-4 transform transition-transform duration-200 ${activeIndex === index ? 'rotate-180' : ''}`}
                />
              </button>
              {activeIndex === index && (
                <div className="mt-2 p-4 bg-white border-l-4 border-[#821BFF] text-[#1e1e1e] mb-8 rounded-lg">
                  {item.answer}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* CTA Card Section */}
      <div className="bg-white p-16 rounded-lg shadow-md max-w-sm flex flex-col items-center text-center z-10">
        <img src={faqImage} alt="Contact" className="w-24 h-24 rounded-full mb-8" />
        <h3 className="text-3xl font-bold text-[#1e1e1e] mb-8">Book a 15-minute intro call</h3>
        <button className="bg-[#1e1e1e] text-white px-6 py-3 rounded-full text-xl font-semibold hover:bg-[#333] transition mb-4">
          Schedule now
        </button>
        <p className="mt-4 text-[#4a4a4a] text-sm">
          Prefer to email?{' '}
          <a href="mailto:hello@designpark.io" className="text-[#e63946] underline">
            hello@designpark.io
          </a>
        </p>
      </div>
    </section>
  );
};

export default Faq;

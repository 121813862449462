import React from 'react';
import logo from '../assets/icons/logo.svg'; // Replace with the actual path to your logo

const Footer = () => {
  return (
    <footer className="py-16 px-6 text-center">
      {/* Logo Section */}
      <div className="mb-8">
        <img src={logo} alt="DesignPark Logo" className="h-36 mx-auto" />
      </div>

      {/* Footer Links */}
      <div className="flex flex-wrap justify-center mt-16 gap-x-16 gap-y-16 max-w-xl mx-auto mb-16 text-[#1e1e1e]">
        <a
          href="https://www.figma.com/proto/oErPeaFUAjubr3k6Vuomyo/Design-Park-Recent-Work?page-id=0%3A1&node-id=1-8150&node-type=frame&viewport=2459%2C1774%2C0.13&t=ltMhTUf3UcaZtMhZ-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1%3A8150"
          target="_blank"
          rel="noopener noreferrer"
          className="text-lg hover:underline"
        >
          Latest projects
        </a>
        <a href="https://designpark.io" className="text-lg hover:underline">
          Pricing
        </a>
        <a href="mailto:hello@designpark.io" className="text-lg hover:underline">
          Contact
        </a>
        <a
          href="https://billing.stripe.com/p/login/bIY9BIeOr4B849ydQQ"
          target="_blank"
          rel="noopener noreferrer"
          className="text-lg hover:underline"
        >
          Client Login
        </a>
        <a href="https://designpark.io" className="text-lg hover:underline">
          Get Started
        </a>
        <a href="/terms-and-conditions" className="text-lg hover:underline">
          Terms & Conditions
        </a>
        <a href="/privacy-policy" className="text-lg hover:underline">
          Privacy Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from "react";
import radar from "../assets/icons/radaryellow.svg"; // Replace with the actual path
import rightArrow from "../assets/icons/arrowright.svg"; // Replace with the actual path
import proIcon from "../assets/icons/pro.svg"; // Replace with the actual path
import logo from "../assets/icons/membershiplogo.svg"; // Replace with the actual path
import circleGraph from "../assets/icons/circlegraph.svg"; // Import the decorative circle graph
import rocket from "../assets/icons/rocket.svg";

const Membership = () => {
  const [plan, setPlan] = useState("Standard");
  const price = plan === "Pro" ? 4500 : 2500;

  const includedItems = {
    Standard: [
      "One request at a time",
      "Average 48 hour delivery",
      "Pause or cancel anytime",
      "Unlimited Stock Photos",
      "Unlimited Brands",
    ],
    Pro: [
      <span key="priority" className="font-bold text-[#1e1e1e]">
        Priority 24 hour delivery
      </span>,
      "Up to two requests at a time",
      "Pause or cancel anytime",
      "Unlimited Stock Photos",
      "Unlimited Users",
      "Unlimited Brands",
    ],
  };

  const stripeLinks = {
    Standard: "https://buy.stripe.com/6oE29IcIY4FL8p24gi",
    Pro: "https://buy.stripe.com/00gaGe7oE0pv20E9AB",
  };

  const handleGetStarted = () => {
    window.location.href = stripeLinks[plan];
  };

  return (
    <section className="relative py-60 px-6 flex flex-col lg:flex-row justify-center items-stretch gap-12">
      <img
        src={rocket}
        alt="Decorative Circle Graph"
        className="absolute top-[100px] rotate-270 right-[120px] w-36 h-36 opacity-100 z-0"
      />

      <div className="bg-[#1e1e1e] text-white rounded-lg p-12 shadow-md flex-1 flex flex-col justify-center min-w-[320px] max-w-[610px] h-[700px]">
        <div className="flex flex-col items-start">
          <div className="inline-flex items-center bg-[#821BFF] py-4 px-8 rounded-full space-x-2 mb-16">
            <img
              src={radar}
              alt="Slots available now"
              className="w-6 h-6 pulse"
            />
            <span className="text-[#fff] text-sm ">
              {plan === "Standard" ? "3 Slots Available At Discount" : "Slots Available Now"}
            </span>
          </div>
          <div className="mb-8">
            <h2 className="text-6xl font-bold mb-0">JOIN</h2>
            <img
              src={logo}
              alt="DesignPark Logo"
              className="h-8 md:h-20 mb-6"
            />
          </div>
          <div className="space-y-6 w-full">
            <a
              href="https://calendly.com/hello-designpark/intro-call-design-park"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#2b2b2b] p-6 rounded-lg flex justify-between items-center group"
            >
              <div className="flex-1">
                <h3 className="text-xl font-semibold mb-2">
                  Book a free 15-min intro call
                </h3>
                <p className="text-sm text-gray-300">
                  Learn how Design Park works and how it can help you
                </p>
              </div>
              <div className="p-2 rounded-full group-hover:bg-[#821BFF] transition duration-300">
                <img src={rightArrow} alt="Arrow Right" className="w-6 h-6" />
              </div>
            </a>

            {/* Refer a friend section with prefilled mailto */}
            <a
              href="mailto:hello@designpark.io?subject=Referral&body=Who are you referring?%0D%0APlease provide their name and contact information.%0D%0AHave they signed up for Design Park yet?%0D%0AAny additional details or context?"
              className="bg-[#2b2b2b] p-6 rounded-lg flex justify-between items-center group"
            >
              <div className="flex-1">
                <h3 className="text-xl font-semibold mb-2">
                  Refer a friend & earn
                </h3>
                <p className="text-sm text-gray-300">
                  Earn 7% monthly recurring revenue for each referral.
                </p>
              </div>
              <div className="p-2 rounded-full group-hover:bg-[#821BFF] transition duration-300">
                <img src={rightArrow} alt="Arrow Right" className="w-6 h-6" />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="relative bg-white rounded-lg p-12 shadow-md flex-1 flex flex-col justify-center min-w-[320px] max-w-[610px] h-[700px]">
        <div className="flex flex-col items-start">
          <h2 className="text-5xl font-bold text-[#1e1e1e] mb-8">Membership</h2>

          <div className="flex items-center w-full md:w-3/5 justify-start mb-8 space-x-2 bg-[#1e1e1e] p-1 rounded-full">
            <button
              className={`px-4 py-2 w-full rounded-full font-semibold ${
                plan === "Standard"
                  ? "bg-yellow-400 text-black"
                  : "bg-transparent text-white"
              }`}
              onClick={() => setPlan("Standard")}
            >
              Standard
            </button>
            <button
              className={`px-4 py-2 w-full rounded-full font-semibold flex justify-center items-center space-x-2 ${
                plan === "Pro"
                  ? "bg-yellow-400 text-black"
                  : "bg-transparent text-white"
              }`}
              onClick={() => setPlan("Pro")}
            >
              <img src={proIcon} alt="Pro Icon" className="w-4 h-4" />
              <span>Pro</span>
            </button>
          </div>

          <div className="text-5xl text-[#1e1e1e] font-bold mb-2">
            {plan === "Pro" ? (
              <>
                ${price}
                <span className="text-lg font-normal">/m</span>
              </>
            ) : (
              <>
                <span className="line-through text-gray-500 text-3xl md:text-5xl">${price}</span>
                <span className="ml-2 text-3xl md:text-5xl">${2000}</span>
                <span className="text-lg font-normal">/m</span>
              </>
            )}
          </div>

          <p className="text-sm text-gray-600 mb-12">
            {plan === "Pro"
              ? "Two requests at a time."
              : "One request at a time."}{" "}
            Pause or cancel anytime.
          </p>

          <hr className="mb-12 w-full" />

          <ul className="text-left text-gray-600 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-2 mb-12">
            {includedItems[plan].map((item, index) => (
              <li key={index} className="list-disc list-inside">
                {item}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex items-center">
          <button
            className="bg-[#1e1e1e] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-[#333] transition"
            onClick={handleGetStarted}
          >
            Get started
          </button>
          <a
            href="https://calendly.com/hello-designpark/intro-call-design-park"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#1e1e1e] ml-4 underline"
          >
            or book a call
          </a>
        </div>
      </div>
    </section>
  );
};

export default Membership;

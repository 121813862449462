import { Link } from 'react-router-dom';

const blogs = [
  { id: 1, title: 'Blog Post 1', excerpt: 'This is the first blog post.' },
  { id: 2, title: 'Blog Post 2', excerpt: 'This is the second blog post.' },
];

const BlogPage = () => {
  return (
    <div className="container mx-auto py-10">
      <h2 className="text-3xl font-bold mb-6">Blog</h2>
      <div className="grid gap-6 md:grid-cols-2">
        {blogs.map((blog) => (
          <Link to={`/blog/${blog.id}`} key={blog.id} className="p-4 bg-gray-100 rounded shadow hover:bg-gray-200">
            <h3 className="text-xl font-semibold">{blog.title}</h3>
            <p>{blog.excerpt}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;

import React from 'react';
import Footer from '../components/Footer';

// Import the icons
import radarIcon from '../assets/icons/radar.svg'; // Add the radar icon

const ThankYou = ({ trelloBoardUrl }) => {  // Pass the Trello board URL dynamically
  return (
    <>
      <section className="text-center py-32 px-4 w-full">
        <div className="max-w-5xl mx-auto">
          <h1 className="text-9xl font-bold text-[#1e1e1e] mb-8">
            Thank You
          </h1>
          <p className="text-3xl text-[#1e1e1e] mb-12">
            Please check your email for a link to set up your <br /> 
            Trello board and start assigning design tasks.
          </p>
          <p className="text-xl text-[#1e1e1e] mb-12">
            If you need assistance, email us at  
            <strong> hello@designpark.io</strong>
          </p>
          {/* 
          <button
            className="bg-[#1a1a1a] text-white px-12 py-4 rounded-full text-3xl font-semibold mb-6 hover:bg-[#333] transition"
            onClick={() => window.location.href = trelloBoardUrl} // Use the passed URL
          >
            Get Started
          </button>
          
          <div className="flex justify-center items-center space-x-2">
            
            <img src={radarIcon} alt="Radar" className="h-6 w-6 pulse" />
            <span className="text-[#1e1e1e] text-base">Ready to go</span>
          </div>
          */}
        </div>
      </section>
      <div className='mt-36'>
      <Footer />
      </div>
    </>
  );
};

export default ThankYou;

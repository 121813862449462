import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import logo from '../assets/icons/logo.svg'; // Replace with your logo path

const Header = () => {
  return (
    <header className="py-8 flex justify-center">
      <Link to="/">
        <img src={logo} alt="DesignPark Logo" className="h-16 w-auto" /> {/* Adjust logo size as needed */}
      </Link>
    </header>
  );
};

export default Header;

// src/components/Hero.jsx
import React from 'react';

// Import the icons
import figmaIcon from '../assets/icons/figma.svg';
import webflowIcon from '../assets/icons/webflow.svg';
import wixIcon from '../assets/icons/wix.svg';
import shopifyIcon from '../assets/icons/shopify.svg';
import instagramIcon from '../assets/icons/instagram.svg';
import diamondIcon from '../assets/icons/diamond.svg'; // Add the diamond icon
import radarIcon from '../assets/icons/radar.svg'; // Add the radar icon

const Hero = ({ onSeePlansClick }) => {
  return (
    <section className=" text-center py-32 px-4 w-full">
      <div className="max-w-5xl mx-auto">
        <h1 className="text-5xl md:text-9xl font-bold text-[#1e1e1e] mb-8">
          A Design Agency
          <br />
          with a Bold Touch
        </h1>
        <p className="text-3xl text-[#1e1e1e] mb-12">
          Design Subscriptions Tailored to You. Pause or cancel Anytime.
        </p>
        <button
          className="bg-[#1a1a1a] text-white px-12 py-4 rounded-full text-3xl font-semibold mb-6 hover:bg-[#333] transition"
          onClick={onSeePlansClick} // Trigger scroll to Membership section
        >
          SEE PLANS
        </button>
        <div className="flex justify-center items-center space-x-2">
          {/* Radar icon with pulsing effect */}
          <img src={radarIcon} alt="Radar" className="h-6 w-6 pulse" />
          <span className="text-[#1e1e1e] text-base">Available now</span>
        </div>

        {/* Container with background and padding for icons */}
        <div className="relative overflow-hidden shadow-xl mt-20 bg-[#282828] p-6 rounded-2xl">
          {/* Scrolling container */}
          <div className="flex items-center space-x-10 animate-scroll">
            {/* Repeat icons for seamless loop */}
            <img src={figmaIcon} alt="Figma" className="h-8" />
            <img src={diamondIcon} alt="Diamond" className="h-2" />
            <img src={webflowIcon} alt="Webflow" className="h-4" />
            <img src={diamondIcon} alt="Diamond" className="h-2" />
            <img src={wixIcon} alt="Wix" className="h-4" />
            <img src={diamondIcon} alt="Diamond" className="h-2" />
            <img src={shopifyIcon} alt="Shopify" className="h-6" />
            <img src={diamondIcon} alt="Diamond" className="h-2" />
            <img src={instagramIcon} alt="Instagram" className="h-6" />

            {/* Repeat icons again to ensure smooth infinite scrolling */}
            <img src={figmaIcon} alt="Figma" className="h-8" />
            <img src={diamondIcon} alt="Diamond" className="h-2" />
            <img src={webflowIcon} alt="Webflow" className="h-4" />
            <img src={diamondIcon} alt="Diamond" className="h-2" />
            <img src={wixIcon} alt="Wix" className="h-6" />
            <img src={diamondIcon} alt="Diamond" className="h-2" />
            <img src={shopifyIcon} alt="Shopify" className="h-6" />
            <img src={diamondIcon} alt="Diamond" className="h-2" />
            <img src={instagramIcon} alt="Instagram" className="h-6" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

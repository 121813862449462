import React from 'react';
import Header from '../components/Header'; // Import your Header component

const TermsAndConditions = () => {
  return (
    <>
      <Header /> {/* Add the Header component here */}
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-8">Terms and Conditions</h1>

        <p className="text-sm text-gray-600 mb-4">Effective Date: <strong>9/12/24</strong></p>

        <div className="space-y-8">
          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Overview</h2>
            <p className="text-base text-gray-600">
              DesignPark ("we," "our," "us") offers design subscription services that allow you ("Client,"
              "Subscriber," "User") to request design work on a recurring or subscription basis. These Terms govern
              your use of our website, services, and any interactions with DesignPark.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Subscription Plans</h2>
            <p className="text-base text-gray-600">
              DesignPark offers various subscription plans that grant access to specific design services. The
              details of each plan, including scope, delivery timelines, and pricing, are provided on our website.
              By subscribing to a plan, you agree to:
            </p>
            <ul className="list-disc ml-6 mt-2 text-base text-gray-600 space-y-2">
              <li>Pay the subscription fees as stated.</li>
              <li>Use the services within the limits of the chosen plan.</li>
              <li>Comply with the guidelines for submitting design requests.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Design Requests</h2>
            <p className="text-base text-gray-600">
              Subscribers can submit design requests based on their subscription plan. DesignPark reserves the right
              to prioritize work based on the selected plan (e.g., Pro Plan subscribers may receive faster
              delivery).
            </p>
            <ul className="list-disc ml-6 mt-2 text-base text-gray-600 space-y-2">
              <li>
                <strong>Revisions:</strong> Each design request includes [number] of revisions as part of the service.
                Additional revisions may incur extra costs.
              </li>
              <li>
                <strong>Ownership:</strong> You retain full ownership of the final design files after full payment
                of the subscription and upon completion of the project.
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Delivery Times</h2>
            <p className="text-base text-gray-600">
              DesignPark strives to deliver designs within the stated timeframes, depending on the subscription plan
              (e.g., 24-hour or 48-hour delivery). However, delivery times may vary due to the complexity of the
              request or unforeseen circumstances.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Payments & Billing</h2>
            <p className="text-base text-gray-600">
              <strong>Payment Terms:</strong> Subscription fees are billed monthly or annually, depending on your
              selected plan.
            </p>
            <p className="text-base text-gray-600">
              <strong>Automatic Renewal:</strong> Subscriptions automatically renew unless canceled before the renewal
              date. You are responsible for ensuring that your payment information is up to date to avoid service
              interruptions.
            </p>
            <p className="text-base text-gray-600">
              <strong>Refund Policy:</strong> DesignPark does not offer refunds for completed services. However, you
              may cancel your subscription at any time to avoid future billing.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Cancellation & Termination</h2>
            <p className="text-base text-gray-600">
              You may cancel your subscription at any time through your account dashboard or by contacting us at
              [email]. Cancellations take effect at the end of the current billing cycle. DesignPark may terminate or
              suspend your account if you violate these Terms or engage in any fraudulent or abusive activity.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Intellectual Property</h2>
            <p className="text-base text-gray-600">
              All intellectual property rights in the designs, content, and materials provided by DesignPark remain
              with us until the project is fully completed and paid for. Upon payment, the ownership of the final
              design files will transfer to you.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. Client Responsibilities</h2>
            <p className="text-base text-gray-600">
              You agree to provide accurate, complete, and timely information required for the completion of design
              requests. Delays in providing required information may affect delivery timelines.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">9. Prohibited Uses</h2>
            <p className="text-base text-gray-600">
              When using DesignPark’s services, you agree not to:
            </p>
            <ul className="list-disc ml-6 mt-2 text-base text-gray-600 space-y-2">
              <li>Use the service for any unlawful purpose.</li>
              <li>Submit any offensive, obscene, or inappropriate content.</li>
              <li>Share, resell, or redistribute DesignPark’s services without permission.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">10. Confidentiality</h2>
            <p className="text-base text-gray-600">
              We respect your privacy and confidentiality. Any information shared with us during your design requests
              will remain confidential and will only be used for the purpose of providing the services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">11. Limitations of Liability</h2>
            <p className="text-base text-gray-600">
              DesignPark is not liable for any direct, indirect, incidental, or consequential damages arising out of
              your use of our services. Our maximum liability to you for any claim arising from your use of the
              services is limited to the amount you have paid to DesignPark in the preceding 12 months.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">12. Modifications to Terms</h2>
            <p className="text-base text-gray-600">
              We reserve the right to modify these Terms at any time. Any changes will be communicated to you via
              email or through our website. Your continued use of DesignPark services after any changes indicates
              your acceptance of the revised Terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">13. Contact Information</h2>
            <p className="text-base text-gray-600">
              If you have any questions or concerns about these Terms, please contact us at:
            </p>
            <p className="text-base text-gray-600">
              <strong>Email:</strong> hello@designpark.io
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;

// src/components/RecentWork.jsx
import React, { useEffect, useRef, useState } from 'react';
import latestProjectsIcon from '../assets/icons/latestprojects.svg'; // Assuming you have this icon in your assets folder

// Directly import images from the src/assets/images directory
import recent1 from '../assets/images/recentwork/recent1.png';
import recent2 from '../assets/images/recentwork/recent2.png';
import recent3 from '../assets/images/recentwork/recent3.png';
import recent4 from '../assets/images/recentwork/recent4.png';
import recent5 from '../assets/images/recentwork/recent8.png';
import recent6 from '../assets/images/recentwork/recent6.png';
import recent7 from '../assets/images/recentwork/recent7.png';

const images = [recent1, recent2, recent3, recent4, recent5, recent6, recent7];

const RecentWork = () => {
  const [inView, setInView] = useState(false);
  const sectionRef = useRef(null);
  const badgeRef = useRef(null);
  const scrollContainerRef = useRef(null);

  // For swipe functionality
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        setInView(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      threshold: 0.1,
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (badgeRef.current) {
      badgeRef.current.classList.toggle('rotate-45', inView);
    }

    if (scrollContainerRef.current) {
      if (inView) {
        scrollContainerRef.current.style.setProperty('--scroll-speed', '20s');
      } else {
        scrollContainerRef.current.style.setProperty('--scroll-speed', '0s');
      }
    }
  }, [inView]);

  // Swipe handlers
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const distance = touchStartX.current - touchEndX.current;
    if (distance > 50 && scrollContainerRef.current) {
      // Swiped left
      scrollContainerRef.current.scrollLeft += 300; // Adjust as necessary
    }
    if (distance < -50 && scrollContainerRef.current) {
      // Swiped right
      scrollContainerRef.current.scrollLeft -= 300; // Adjust as necessary
    }
  };

  return (
    <section ref={sectionRef} className=" py-4 w-full">
      <div className="relative flex items-center justify-center mb-8 px-6">
        <img
          ref={badgeRef}
          src={latestProjectsIcon}
          alt="Latest Projects"
          className="h-[150px] absolute -top-10 left-[20%] z-10 transition-transform"
        />
      </div>
      <div
        ref={scrollContainerRef}
        className="flex space-x-6 overflow-x-scroll w-full px-6 transition-transform duration-500 scrollbar-hide"
        style={{ '--scroll-speed': '0s' }} // Initial scroll speed set to 0
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {[...images, ...images].map((image, index) => (
          <div
            key={index}
            className="flex-shrink-0 w-[250px] h-[320px] rounded-lg shadow-md bg-black overflow-hidden group transition-transform transform hover:scale-105"
          >
            <img
              src={image}
              alt={`Recent Work ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default RecentWork;

// src/components/TypeOfWork.jsx
import React, { useState } from 'react';
import curvedLine from '../assets/icons/curvedline.svg'; // Replace with the actual path to the curved line
import arrowline from '../assets/icons/arrowline.png'; // Import the arrowline icon
import ruler from '../assets/icons/ruler.svg';

// Import images from the recent work folder
import recent1 from '../assets/images/recentwork/Work1.png';
import recent2 from '../assets/images/recentwork/Work2.png';
import recent3 from '../assets/images/recentwork/Work3.png';
import recent4 from '../assets/images/recentwork/Work4.png';
import recent5 from '../assets/images/recentwork/Work5.png';
import recent6 from '../assets/images/recentwork/Work6.png'; // Corrected import to recent6.png

// Define tags with their background colors
const tags = [
  { id: 1, label: 'Landing Pages', bgColor: '#18B600' },
  { id: 2, label: 'Mobile Apps', bgColor: '#4E4EFF' },
  { id: 3, label: 'Logos', bgColor: '#02B8E8' },
  { id: 4, label: 'Branding', bgColor: '#FFDC00' },
  { id: 5, label: 'Slide Decks', bgColor: '#FF3E1A' },
  { id: 6, label: 'SaaS', bgColor: '#821BFF' },
  { id: 7, label: 'Social media', bgColor: '#FF3E1A' },
  { id: 8, label: 'Icons', bgColor: '#FFDC00' },
  { id: 9, label: 'Brand Guides', bgColor: '#4E4EFF' },
  { id: 10, label: 'Blog Graphics', bgColor: '#02B8E8' },
  { id: 11, label: 'Dashboards', bgColor: '#18B600' },
  { id: 12, label: '+ more', bgColor: '#1e1e1e' },
];

// Array of recent work images
const workItems = [
  { id: 1, img: recent1 },
  { id: 2, img: recent2 },
  { id: 3, img: recent3 },
  { id: 4, img: recent4 },
  { id: 5, img: recent5 },
  { id: 6, img: recent6 },
];

const TypeOfWork = () => {
  // State to manage the modal visibility and selected image
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to open the modal with the selected image
  const openModal = (img) => {
    setSelectedImage(img);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  return (
    <section className="relative py-0 px-6 text-center">
      {/* Curved Line */}
      <img src={curvedLine} alt="Curved Line" className="h-48 mx-auto z-10 relative" />

      {/* Decorative Arrow Line Icon */}
      <img
        src={arrowline}
        alt="Arrow Line"
        className="absolute top-[600px] left-[300px] w-72 h-72 opacity-100 z-0"
      />
      <img
        src={ruler}
        alt="Arrow Line"
        className="absolute top-[1000px] rotate-90 right-[50px] w-100 h-100 opacity-80 z-0"
      />

      {/* Heading */}
      <div className="max-w-2xl mx-auto mt-24 mb-16 relative z-10">
        <h2 className="text-7xl font-bold text-[#1e1e1e] mb-4">Apps, websites, logos & more</h2>
      </div>

      {/* Tags */}
      <div className="flex flex-wrap justify-center items-center gap-3 max-w-5xl mx-auto mb-16 relative z-10">
        {tags.map((tag) => (
          <span
            key={tag.id}
            className="px-8 py-3 text-white rounded-full"
            style={{ backgroundColor: tag.bgColor }}
          >
            {tag.label}
          </span>
        ))}
      </div>

      {/* Grid of Work Items */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto mb-12 relative z-10">
        {workItems.map((item) => (
          <div
            key={item.id}
            className="w-full h-full rounded-lg overflow-hidden bg-gray-200 cursor-pointer hover:scale-95 transition-transform"
            onClick={() => openModal(item.img)}
          >
            <img src={item.img} alt={`Work ${item.id}`} className="w-full h-full object-cover" />
          </div>
        ))}
      </div>

      {/* Lightbox Modal */}
      {modalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
          onClick={closeModal} // Close modal on clicking outside the image
        >
          <div className="relative" onClick={(e) => e.stopPropagation()}> {/* Prevent closing when clicking on the image */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-white text-2xl font-bold"
            >
              ✕
            </button>
            <img src={selectedImage} alt="Selected Work" className="max-w-full max-h-[80vh] rounded-lg" />
          </div>
        </div>
      )}

      {/* See More Work Button */}
      <button className="bg-[#1a1a1a] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-[#333] transition">
  <a 
    href="https://www.figma.com/proto/oErPeaFUAjubr3k6Vuomyo/Design-Park-Recent-Work?page-id=0%3A1&node-id=1-8150&node-type=frame&viewport=2459%2C1774%2C0.13&t=ltMhTUf3UcaZtMhZ-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1%3A8150"
    target="_blank"
    rel="noopener noreferrer"
    className="block w-full h-full"
  >
    SEE MORE WORK
  </a>
</button>

    </section>
  );
};

export default TypeOfWork;
